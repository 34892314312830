import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Gallery from 'react-photo-gallery'
import styles from './CollectionPageTemplate.module.scss'
import CWD from '../components/CenteredWidthLimiter/CenteredWidthLimiter'
import MediaQuery from 'react-responsive'
import Lightbox from '../components/Lightbox/Lightbox'
import PageTitle from '../components/PageTitle/PageTitle'
import SEO from '../components/SEO/SEO'

export default function CollectionPage({ data, pageContext }) {
  const photos = data.paintings.edges.map(painting => {
    const id = painting.node.id
    const frontmatter = painting.node.frontmatter
    const image = painting.node.frontmatter.image.childImageSharp

    return {
      key: id,
      src: image.original.src,
      fluid: image.fluid,
      width: image.original.width,
      height: image.original.height,
      title: frontmatter.title,
      price: frontmatter.price,
      gallery: frontmatter.gallery,
      dimensions: frontmatter.sizes,
      sold: frontmatter.sold,
    }
  })

  return (
    <>
      <SEO title={`Collection ${pageContext.collection}`} />
      <CWD>
        <PageTitle title={pageContext.collection} />
        <MediaQuery minWidth="40em">
          {matches => {
            const columns = matches ? 3 : 2
            return (
              <div className={styles.gallery}>
                <Gallery
                  photos={photos}
                  direction="column"
                  columns={columns}
                  margin={5}
                  renderImage={({ index, photo, top, left, margin }) => {
                    const width = photo.width
                    return (
                      <Lightbox key={photo.key} photo={photo}>
                        <button
                          className={styles.button}
                          style={{
                            top: top,
                            left: left,
                            width: width,
                            position: 'absolute',
                            margin: margin,
                          }}
                        >
                          <Img fluid={photos[index].fluid} />
                        </button>
                      </Lightbox>
                    )
                  }}
                />
              </div>
            )
          }}
        </MediaQuery>
      </CWD>
    </>
  )
}

export const collectionPageQuery = graphql`
  query($collection: String!) {
    paintings: allMarkdownRemark(
      filter: {
        frontmatter: { collection: { eq: $collection }, offline: { eq: false } }
      }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            price
            gallery
            offline
            sold
            date
            image {
              childImageSharp {
                original {
                  src
                  width
                  height
                }
                fluid(maxWidth: 1024) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            sizes {
              heightCm
              widthCm
              heightIn
              widthIn
            }
          }
        }
      }
    }
  }
`
