// modules
import React, { useState } from 'react'
import Img from 'gatsby-image'
import posed, { PoseGroup } from 'react-pose'
// styles
import styles from './Lightbox.module.scss'
// animations
import { animations } from './LightboxAnimations'

import CrossIcon from '../icons/CrossIcon'

// definitions
const Backdrop = posed.div(animations.Backdrop)
const LightboxContent = posed.div(animations.ImageWrapper)

function Lightbox({ children, photo }) {
  // state
  const [isActive, setIsActive] = useState(false)
  // handlers
  const handleClick = () => setIsActive(!isActive)
  // jsx
  if (photo && !photo.offline) {
    const heightCm = photo.dimensions.heightCm
    const widthCm = photo.dimensions.widthCm
    const heightIn = photo.dimensions.heightIn
    const widthIn = photo.dimensions.widthIn

    return (
      <div onClick={handleClick} style={{ cursor: 'zoom-in' }}>
        {children}
        <PoseGroup flipMove={false}>
          {isActive ? (
            <Backdrop
              key={photo.key}
              className={styles.Backdrop}
              onClick={handleClick}
            >
              <LightboxContent className={styles.content}>
                <div className={styles.paintingInfos}>
                  <h2 className={styles.lightboxTitle}>{photo.title}</h2>
                  <p>
                    {`${heightCm} x ${widthCm} cm`}
                    <span
                      className={styles.grayText}
                    >{` (${heightIn} x ${widthIn} in)`}</span>
                  </p>
                  {photo.sold ? (
                    <p className={styles.notAvailable}>
                      N’est plus disponible / No longer available
                    </p>
                  ) : photo.price && photo.price !== 0 ? (
                    <p>{`${photo.price} €`}</p>
                  ) : (
                    <p className={styles.priceOnDemand}>
                      Prix sur demande / Price upon request
                    </p>
                  )}
                  {!photo.sold && photo.gallery ? (
                    <p className={styles.grayText}>{photo.gallery}</p>
                  ) : null}
                  <div className={styles.iconWrapper}>
                    <CrossIcon />
                  </div>
                </div>
                <Img
                  className={styles.image}
                  fluid={photo.fluid}
                  imgStyle={{ objectFit: 'contain' }}
                  title={photo.title}
                  alt={photo.title}
                />
              </LightboxContent>
              {/* </CWD> */}
            </Backdrop>
          ) : null}
        </PoseGroup>
      </div>
    )
  } else {
    return null
  }
}

// exports
export default Lightbox
